import { Language } from '@kerberos-compliance/lib-adp-shared/shared/i18n/enums/language.enums';
import { Gender } from '../enums/gender.enum';

export enum Industry {
  RealEstate = 'realEstate',
  TradeInGoods = 'tradeInGoods',
}

/*
    User Model
*/
export interface User {
  uid: string;
  email: string;
  displayName?: string | null;
  firstName: string;
  lastName: string;
  kerberosAccountId: string;
  gender: Gender;
  preferredLanguage: Language;
  salutation: string;
  industry: Industry;
}
