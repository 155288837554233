<button
  appRipple
  [appRippleDisabled]="disabled || active"
  appRippleColor="rgba(6, 58, 168, 0.50)"
  class="flex w-20 justify-center rounded-md border border-transparent outline-none"
  [ngClass]="{
    'hover:bg-blue-hover hover:border-blue-hover focus-visible:border-secondary': !active && !disabled,
    'border-brand bg-blue text-white': active && !disabled,
    'text-brand': !active && !icon && !disabled,
    'bg-base-100 border-white py-1 px-3': !icon,
    'p-1': icon,
    'text-gray-900': icon && !disabled,
    'text-base-content': disabled
  }"
  [disabled]="disabled"
>
  <ng-content></ng-content>
</button>
