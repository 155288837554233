import { registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import de from '@angular/common/locales/de';
import en from '@angular/common/locales/en';
import { inject, provideAppInitializer } from '@angular/core';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import {
  MSAL_GUARD_CONFIG,
  MSAL_INSTANCE,
  MSAL_INTERCEPTOR_CONFIG,
  MsalBroadcastService,
  MsalGuard,
  MsalInterceptor,
  MsalService,
} from '@azure/msal-angular';
import { EnvironmentService } from '@core/services/environment.service';
import { IconRegistryProvider } from '@core/services/icon-registry.service';
import { bootUpFactory } from '@core/utils/app-initializer';
import { provideErrorTrackerServices } from '@core/utils/error-tracker-initializer';
import { environment } from '@env/environment';
import { MSALGuardConfigFactory, MSALInstanceFactory, MSALInterceptorConfigFactory } from '@env/msal.config';
import { TranslocoService } from '@jsverse/transloco';
import { Language, LanguageMap } from '@kerberos-compliance/lib-adp-shared/shared/i18n/enums/language.enums';
import { DefaultDataServiceConfig } from '@ngrx/data';
import { Store } from '@ngrx/store';
import { de_DE, en_US, NZ_I18N, NzI18nInterface } from 'ng-zorro-antd/i18n';
import { KerberosTokenInterceptor } from '@/auth/interceptor/kerberos.interceptor';
import { KerberosAuthService } from '@/auth/services/kerberos-auth.service';
import { provideNzIcons } from 'ng-zorro-antd/icon';
import { icons } from '@core/services/icons.provider';

registerLocaleData(en);
registerLocaleData(de);

export const MinimalProviders = [
  provideAppInitializer(() => {
    const initializerFunction = bootUpFactory(
      inject(MsalService),
      inject<Store>(Store),
      inject(TranslocoService),
      inject(MsalBroadcastService),
      inject(Router),
      inject(KerberosAuthService),
      inject(EnvironmentService),
    );
    return initializerFunction();
  }),
  { provide: NZ_I18N, useValue: de_DE },
  {
    provide: NZ_I18N,
    useFactory: (translationService: TranslocoService): NzI18nInterface => {
      return translationService.getActiveLang() === LanguageMap.German ? de_DE : en_US;
    },
    deps: [TranslocoService],
  },
  {
    provide: NZ_I18N,
    useFactory: (language: Language): Language =>
      language === LanguageMap.German ? LanguageMap.German : LanguageMap.English,
  },
  {
    provide: DefaultDataServiceConfig,
    useValue: {
      root: environment.config.dms.url,
      timeout: environment.config.dms.timeout,
    },
  },
  {
    provide: 'ENVIRONMENT_CONFIG',
    useValue: environment.config,
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: MsalInterceptor,
    multi: true,
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: KerberosTokenInterceptor,
    multi: true,
  },
  {
    provide: MSAL_INSTANCE,
    useFactory: MSALInstanceFactory,
    deps: ['ENVIRONMENT_CONFIG'],
  },
  {
    provide: MSAL_GUARD_CONFIG,
    useFactory: MSALGuardConfigFactory,
  },
  {
    provide: MSAL_INTERCEPTOR_CONFIG,
    useFactory: MSALInterceptorConfigFactory,
    deps: ['ENVIRONMENT_CONFIG'],
  },
  ...provideErrorTrackerServices(),
  MsalGuard,
  MsalService,
  MatSnackBarModule,
  IconRegistryProvider,
  provideNzIcons(icons),
];
