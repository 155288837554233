import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { isDevMode, NgModule } from '@angular/core';
import { provideTransloco, TranslocoModule } from '@jsverse/transloco';
import { LanguageMap } from '@kerberos-compliance/lib-adp-shared/shared/i18n/enums/language.enums';
import { TranslocoHttpLoader } from './transloco-loader';

@NgModule({
  exports: [TranslocoModule],
  imports: [TranslocoModule],
  providers: [
    provideTransloco({
      config: {
        availableLangs: [LanguageMap.English, LanguageMap.German],
        defaultLang: LanguageMap.English,
        // Remove this option if your application doesn't support changing language in runtime.
        reRenderOnLangChange: true,
        prodMode: !isDevMode(),
      },
      loader: TranslocoHttpLoader,
    }),
    provideHttpClient(withInterceptorsFromDi()),
  ],
})
export class TranslocoRootModule {}
