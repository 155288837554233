import 'dayjs/locale/de';
import { UUID } from 'node:crypto';
import { ActivatedRoute } from '@angular/router';
import { Language } from '@kerberos-compliance/lib-adp-shared/localization/language';
import {
  RiskValuesForDD,
  RiskValuesForKYC,
  RiskValuesForPoS,
} from '@kerberos-compliance/lib-adp-shared/work-item-types/adp.kyc.generic-dd/risk-rating.types';
import { WorkItemStatus } from '@kerberos-compliance/lib-adp-shared/work-items/enums/work-item.enum';
import { AmlLibraryElementEndpoints } from '@kyc/types/kyc.types';
import { FormlyFieldConfig } from '@ngx-formly/core';
import dayjs, { extend } from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import { Risks, TranslationKeys } from '../enums/kyc.enum';

extend(advancedFormat);

/**
 * Converts a plain date string to a localized date format.
 *
 * @param {string} plainDate - The date string to be converted.
 * @param {string} [lang] - Optional language code for localization. Defaults to '' (English).
 * @param {boolean} [withSeconds] - Optional flag to include seconds in the formatted output. Defaults to false.
 * @return {string} The localized and formatted date string.
 */
export const convertToLocalDateFormat = (
  plainDate: string,
  lang: string = '',
  withSeconds: boolean = false,
): string => {
  if (withSeconds) {
    return dayjs(plainDate)
      .locale(lang.includes('de') ? 'de' : 'en')
      .format('Do MMM YY HH:mm:ss');
  }

  return dayjs(plainDate)
    .locale(lang.includes('de') ? 'de' : 'en')
    .format('Do MMM YY');
};

/**
 * Remaps an array of FormlyFieldConfig objects with specified endpoints and configurations.
 *
 * @param {FormlyFieldConfig[]} formlyFieldConfig - Array of formly field configuration objects to be remapped.
 * @param {string} endpoint - The endpoint to be used for remapping the field configurations.
 * @param {string} webSocketUrl - The websocket URL for real-time communication.
 * @param {string} accessToken - The access token for authentication purposes.
 * @param {string} downloadEndpoint - The endpoint to handle file downloads.
 * @param {Language} language - The language configuration for localization.
 * @param {string} deleteFileEndpoint - The endpoint to handle file deletion.
 * @return {FormlyFieldConfig[]} The remapped array of FormlyFieldConfig objects.
 */
export function remapWithEndpointForArray(
  formlyFieldConfig: FormlyFieldConfig[],
  endpoint: string,
  webSocketUrl: string,
  accessToken: string,
  downloadEndpoint: string,
  language: Language,
  deleteFileEndpoint: string,
): FormlyFieldConfig[] {
  // eslint-disable-next-line @typescript-eslint/no-use-before-define
  return formlyFieldConfig.map((field) =>
    remapWithEndpointForField(
      field,
      endpoint,
      webSocketUrl,
      accessToken,
      downloadEndpoint,
      language,
      deleteFileEndpoint,
    ),
  );
}

/**
 * Remaps the configuration of a Formly field to include specified endpoints and other connection details.
 * Adjusts field arrays and field groups recursively if they exist, and updates properties for specific types like 'file' and 'select'.
 *
 * @param {FormlyFieldConfig} formlyFieldConfig - The Formly field configuration to be modified.
 * @param {string} endpoint - The endpoint URL to be included in the configuration.
 * @param {string} webSocketUrl - The WebSocket URL to be included in the configuration.
 * @param {string} accessToken - The access token for authorization.
 * @param {string} downloadEndpoint - The download endpoint URL to be included in the configuration.
 * @param {Language} language - The language setting to be included in the configuration.
 * @return {FormlyFieldConfig} The modified Formly field configuration with the specified details included.
 */
export function remapWithEndpointForField(
  formlyFieldConfig: FormlyFieldConfig,
  endpoint: string,
  webSocketUrl: string,
  accessToken: string,
  downloadEndpoint: string,
  language: Language,
  deleteFileEndpoint: string,
): FormlyFieldConfig {
  const temporaryFormlyFieldConfig: Record<string, unknown> = { ...formlyFieldConfig };
  if (formlyFieldConfig.fieldArray) {
    // formlyFieldConfig.fieldArray = remapWithEndpointForField(
    temporaryFormlyFieldConfig['fieldArray'] = remapWithEndpointForField(
      formlyFieldConfig.fieldArray as FormlyFieldConfig,
      endpoint,
      webSocketUrl,
      accessToken,
      downloadEndpoint,
      language,
      deleteFileEndpoint,
    );
  }
  if (formlyFieldConfig.fieldGroup) {
    // formlyFieldConfig.fieldGroup = remapWithEndpointForArray(formlyFieldConfig.fieldGroup, endpoint, webSocketUrl);
    temporaryFormlyFieldConfig['fieldGroup'] = remapWithEndpointForArray(
      formlyFieldConfig.fieldGroup,
      endpoint,
      webSocketUrl,
      accessToken,
      downloadEndpoint,
      language,
      deleteFileEndpoint,
    );
  }

  if (formlyFieldConfig.type === 'file') {
    temporaryFormlyFieldConfig['props'] = {
      ...formlyFieldConfig.props,
      endpoint,
      webSocketUrl,
      accessToken,
      downloadEndpoint,
      language,
      deleteFileEndpoint,
    };
  }

  if (formlyFieldConfig.type === 'select') {
    temporaryFormlyFieldConfig['props'] = {
      ...formlyFieldConfig.props,
      endpoint,
      webSocketUrl,
      accessToken,
      downloadEndpoint,
      language,
      deleteFileEndpoint,
    };
  }
  return temporaryFormlyFieldConfig;
}

/**
 * Maps the numerical risk rating to a corresponding risk level string.
 *
 * @param {number} riskRating - The numerical risk rating to be mapped.
 * @return {string} The corresponding risk level as a string.
 */
export const mapRiskRatingForKYC = (riskRating: number): string => {
  let riskValue = '';
  switch (riskRating) {
    case 0:
    case 1:
    case 2:
    case 3: {
      riskValue = Risks.Low;
      break;
    }
    case 4:
    case 5:
    case 6:
    case 7: {
      riskValue = Risks.Medium;
      break;
    }
    case 8:
    case 9:
    case 10: {
      riskValue = Risks.High;
      break;
    }
    case 99: {
      riskValue = Risks.Critical;
      break;
    }
  }
  return riskValue;
};

/**
 * Maps a risk rating to a corresponding string value based on the work item type.
 *
 * @param {number} riskRating - The numeric risk rating to be mapped.
 * @param {string} workItemType - The type of work item being evaluated.
 * @return {string} The mapped risk value as a string.
 */
export const mapRiskRating = (riskRating: number | string, workItemType: string): string => {
  if (workItemType.includes('ddt-')) {
    return String(riskRating);
  }
  return riskRating as string;
};

/**
 * Retrieves the risk rating options for the specified work item type.
 *
 * @returns {string[]} The list of risk rating options corresponding to the work item type.
 *
 * The `workItemTypeId` can correspond to various categories of Due Diligence or KYC checks:
 * - 1: Due Diligence Check - Location
 * - 2: Due Diligence Check - Individual
 * - 3: Due Diligence Check - Organization
 * - 4 and 5: KYC checks
 * @param workItemTypeReferenceName
 */
export const getRiskRatingOptionsForType = (workItemTypeReferenceName: string): string[] => {
  let riskValues: string[] = [];
  switch (workItemTypeReferenceName) {
    // 1	Due Diligence Check - Location
    // 2	Due Diligence Check - Individual
    // 3	Due Diligence Check - Organization
    case 'ddt-pos.kyc.adp': {
      riskValues = [...RiskValuesForPoS];
      break;
    }
    case 'ddt-np.kyc.adp':
    case 'ddt-org.kyc.adp': {
      riskValues = [...RiskValuesForDD];
      break;
    }
    case 'kyc-np.kyc.adp':
    case 'kyc-org.kyc.adp': {
      riskValues = [...RiskValuesForKYC];
      break;
    }
  }
  return riskValues;
};

/**
 * Generates component endpoints based on the provided base URL and work item ID.
 *
 * @param {string} baseUrl - The base URL for constructing the endpoints.
 * @param {UUID} workItemId - The unique identifier for the work item.
 * @return {AmlLibraryElementEndpoints} An object containing upload, download, and websocket endpoints specific to the work item.
 */
export const getComponentEndpoints = (baseUrl: string, workItemId: UUID): AmlLibraryElementEndpoints => {
  return {
    uploadEndpointWorkItem: `${baseUrl}/work-item/data/${workItemId}/upload`,
    downloadEndpointWorkItem: `${baseUrl}/work-item/data/${workItemId}`,
    deleteEndpointWorkItem: `${baseUrl}/work-item/data/${workItemId}/:dataReferenceId`,
    webSocketUrl: baseUrl,
  } as const;
};

/**
 * Retrieves a parameter from the current route.
 *
 * @param {ActivatedRoute} route - The current route.
 * @param {string} parameter - The name of the parameter to retrieve.
 * @returns {string} The value of the specified parameter.
 */
export const retrieveParameterFromUrl = (route: ActivatedRoute, parameter: string): string => {
  return route.snapshot.paramMap.get(parameter) ?? '';
};

/**
 * Returns the reverse mapping of work item statuses for the given translation key.
 *
 * @param {TranslationKeys} label - The translation key to get the reverse mapping for.
 * @return {WorkItemStatus[]} - The reverse mapping of work item statuses related to the given translation key.
 */
export const getReverseMappingWorkItemStatus = (label: TranslationKeys): WorkItemStatus[] => {
  switch (label) {
    case TranslationKeys.Draft: {
      return [WorkItemStatus.Draft];
    }
    case TranslationKeys.InProcess: {
      return [
        WorkItemStatus.ActionInternal,
        WorkItemStatus.ActionExternal,
        WorkItemStatus.Subprocess,
        WorkItemStatus.ReviewInternal,
        WorkItemStatus.ReviewExternal,
      ];
    }
    case TranslationKeys.ActionRequired: {
      return [WorkItemStatus.ActionCustomer];
    }
    case TranslationKeys.Done: {
      return [WorkItemStatus.Done];
    }
    case TranslationKeys.OnHold: {
      return [WorkItemStatus.OnHold];
    }
    case TranslationKeys.Cancelled: {
      return [WorkItemStatus.Cancelled];
    }
    default: {
      return [];
    }
  }
};

/**
 * Returns the translation key based on the given work item status.
 * @param {WorkItemStatus} status - The work item status.
 * @returns {TranslationKeys | null} - The translation key for the status.
 */
export const getTranslationKeyForStatus = (status: WorkItemStatus): TranslationKeys | null => {
  switch (status) {
    case WorkItemStatus.Draft: {
      return TranslationKeys.Draft;
    }
    case WorkItemStatus.ActionExternal:
    case WorkItemStatus.ActionInternal:
    case WorkItemStatus.Subprocess:
    case WorkItemStatus.ReviewExternal:
    case WorkItemStatus.ReviewInternal: {
      return TranslationKeys.InProcess;
    }
    case WorkItemStatus.ReviewCustomer:
    case WorkItemStatus.ActionCustomer: {
      return TranslationKeys.ActionRequired;
    }
    case WorkItemStatus.Done: {
      return TranslationKeys.Done;
    }
    case WorkItemStatus.OnHold: {
      return TranslationKeys.OnHold;
    }
    case WorkItemStatus.Cancelled: {
      return TranslationKeys.Cancelled;
    }
  }
};
