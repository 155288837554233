@if (showDownloadDropdown || showPageSizeSelection) {
  <div class="flex w-full flex-row-reverse">
    @if (showDownloadDropdown) {
      <div class="dropdown dropdown-end">
        <div tabindex="0" class="cursor-pointer rounded-lg bg-gray-300 p-2 text-black">...</div>
        <ul tabindex="0" class="menu dropdown-content z-[1] w-42 rounded-box bg-base-100 p-2 shadow">
          <li>
            <button (click)="downloadCSV($event)" class="m-0 p-2 text-sm hover:bg-accent">
              {{ downloadCsvText }}
            </button>
          </li>
          <li>
            <button (click)="downloadXLSX($event)" class="m-0 p-2 text-sm hover:bg-accent">
              {{ downloadXlsxText }}
            </button>
          </li>
        </ul>
      </div>
    }
    @if (showPageSizeSelection) {
      <div class="dropdown mr-2">
        <div tabindex="0" class="cursor-pointer rounded-lg bg-gray-300 p-2 text-black">
          {{ pageSizeSelected }} {{ entriesPerPageText }}
        </div>
        <ul tabindex="0" class="menu dropdown-content z-[1] w-42 rounded-box bg-base-100 p-2 shadow">
          @for (pageSize of pageSizes; track pageSize) {
            <li>
              <button (click)="onChangePageSize(pageSize)" class="m-0 p-2 text-sm hover:bg-accent">
                {{ pageSize }}
              </button>
            </li>
          }
        </ul>
      </div>
    }
  </div>
}
<div #tableContainer>
  <table mat-table [dataSource]="data$" class="mt-4 w-full">
    @for (column of columns; track $index) {
      <ng-container>
        <ng-container matColumnDef="{{ column.key }}">
          <th
            mat-header-cell
            *matHeaderCellDef
            class="bg-gray-300 text-base font-medium text-gray-1000"
            [attr.data-testid]="column.key"
            scope="colgroup"
          >
            <div class="flex w-full flex-row items-center justify-between">
              <span [ngClass]="{ 'ml-2': column.sortable === false }">
                {{ column.title }}
              </span>
              @if (column.sortable !== false) {
                <app-sort-button
                  [active]="sortColumn === column.key"
                  (sort)="onSort(column.key, $event)"
                  class="mr-1"
                ></app-sort-button>
              }
            </div>
          </th>
          <td mat-cell *matCellDef="let element" class="max-w-xs truncate">
            @if (column.template) {
              <ng-container>
                @if (column.template.getContent && column.template.getContent(element)) {
                  <ng-template
                    [ngComponentOutlet]="column.template.component"
                    [ngComponentOutletContent]="createContentNode(column.template.getContent(element))"
                    [ndcDynamicInputs]="column.template.getInputs ? column.template.getInputs(element) : {}"
                    [ndcDynamicOutputs]="column.template.getOutputs ? column.template.getOutputs(element) : {}"
                  ></ng-template>
                }
              </ng-container>
            }
            @if (!column.template) {
              <ng-container>
                {{ column.callback ? column.callback(element) : element[column.key] }}
              </ng-container>
            }
          </td>
        </ng-container>
      </ng-container>
    }

    <tr mat-header-row *matHeaderRowDef="columns | extractKey: 'key'"></tr>
    <tr
      mat-row
      *matRowDef="let row; let i = index; columns: columns | extractKey: 'key'"
      [ngClass]="getEvaluation(row)"
      (click)="clickable && rowClickHandler($event, row)"
      (contextmenu)="onRowContextMenu($event, row)"
      class="cursor-pointer"
    ></tr>
  </table>
</div>
