import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { TranslocoService } from '@jsverse/transloco';
import { WorkItemStatus } from '@kerberos-compliance/lib-adp-shared/work-items/enums/work-item.enum';
import { SortTable } from '@kyc/types/kyc.types';
import { getTranslationKeyForStatus } from '@kyc/utils/kyc.utils';
import { Papa } from 'ngx-papaparse';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import * as XLSX from 'xlsx';
import { Option } from '@/shared/components/fragments/dropdown/dropdown.component';
import { FileDownloadService } from '@/shared/services/file/file-download.service';
import { Sort, SortField, SortOrder } from '../../../pipes/sort-data.pipe';

type DefaultConstructor<T> = new (...arguments_: unknown[]) => T;

export type TemplateComponent<T, K> = {
  component: DefaultConstructor<K>;
  getContent?: (item: T) => string | undefined;
  getInputs?: (item: T) => Record<string, string>;
  getOutputs?: (item: T) => Record<string, (...arguments_: unknown[]) => void>;
};

export type TableColumn<T, K> = {
  title?: string;
  titleKey?: string;
  key: string;
  sortable?: boolean;
  callback?: (item: T) => string;
  template?: TemplateComponent<T, K>;
};

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
})
export class TableComponent<T, K> implements OnInit {
  @ViewChild('tableContainer') tableContainer!: ElementRef;

  @Input() columns!: TableColumn<T, K>[];

  @Input() data$!: Observable<T[]>;

  @Input() isZebra: boolean = false;

  @Input() evaluationFunctionForRowStyling!: (row: T) => string;

  @Input() clickable: boolean = false;

  @Input() showDownloadDropdown: boolean = false;

  @Input() showPageSizeSelection: boolean = false;

  @Input() rowClickHandler!: (event: MouseEvent, row: T) => void;

  @Input() loading: boolean | null = false;

  @Input() error: string | undefined;

  @Input() downloadCsvText: string = '';

  @Input() downloadXlsxText: string = '';

  @Input() entriesPerPageText: string = '';

  sortColumn?: string;

  pageSizeSelected: number = 25;

  @Output() pageChange = new EventEmitter<number>();

  @Output()
  // eslint-disable-next-line unicorn/prefer-event-target
  sort = new EventEmitter<Sort | SortTable | Option>();

  pageSizes: number[] = [25, 50, 100, 200];

  constructor(
    private readonly downloadFileService: FileDownloadService,
    private csvParser: Papa,
    private readonly translationService: TranslocoService,
  ) {}

  ngOnInit(): void {
    if (!this.rowClickHandler) {
      this.rowClickHandler = (event: MouseEvent, row: T): void => {};
    }
  }

  /**
   * Evaluates the given table row using a predefined evaluation function for row styling.
   *
   * @param {T} tableRow - The table row to be evaluated.
   * @return {string} The result of the evaluation function, or an empty string if the function is undefined.
   */
  getEvaluation(tableRow: T): string {
    return this.evaluationFunctionForRowStyling === undefined ? '' : this.evaluationFunctionForRowStyling(tableRow);
  }

  /**
   * Handles sorting logic by setting the sort column and emitting a sort event.
   *
   * @param {SortField} field - The field by which to sort.
   * @param {SortOrder} order - The order in which to sort (e.g., ascending, descending).
   * @return {void}
   */
  onSort(field: SortField, order: SortOrder): void {
    this.sortColumn = order === '' ? undefined : field;
    this.sort.emit({ field, order });
  }

  /**
   * Handles the event when the page size is changed.
   * It updates the selected page size and emits a page change event with the new size.
   *
   * @param {number} size - The new page size selected.
   * @return {void}
   */
  onChangePageSize(size: number): void {
    this.pageSizeSelected = size;
    this.pageChange.emit(size);
  }

  /**
   * Creates a content node from the provided content.
   *
   * @param {unknown} content - The content to create a node from. This can be of any type, but will typically be a string.
   * @return {Text[][]} A 2D array of Text nodes created from the content. If the content is empty or falsy, an empty array is returned.
   */
  createContentNode(content: unknown): Text[][] {
    if (!content) {
      return [];
    }
    if (typeof content === 'string' && content.length === 0) {
      return [];
    }

    return [[document.createTextNode(String(content))]];
  }

  /**
   * Downloads the CSV representation of the current data set.
   *
   * @param {MouseEvent} event - The mouse event triggering the download.
   * @return {void} This method does not return a value.
   */
  downloadCSV(event: MouseEvent): void {
    event.stopPropagation();
    this.data$.pipe(take(1)).subscribe((data) => {
      const mappedData = data.map((row) => ({
        ...row,
        status: this.translationService.translate(
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-expect-error
          'kyc.overviewPage.columns.status.' + getTranslationKeyForStatus(row.status as WorkItemStatus),
        ),
      }));
      const csvData = mappedData.map((row) => {
        const rowData: { [key: string]: unknown } = {};
        for (const column of this.columns) {
          rowData[column.title!] = column.callback ? column.callback(row) : row[column.key as keyof T];
        }
        return rowData;
      });

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      const csv = this.csvParser.unparse(csvData, { delimiter: ';' });
      this.downloadFileService.triggerDownloadCsv(csv);
    });
  }

  /**
   * Downloads the XLSX representation of the current data set.
   *
   * @param {MouseEvent} event - The mouse event triggering the download.
   * @return {void} This method does not return a value.
   */
  downloadXLSX(event: MouseEvent): void {
    event.stopPropagation();
    const nativeEle = this.tableContainer.nativeElement as HTMLTableElement;
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(nativeEle); // converts a DOM TABLE element to a worksheet
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Standortanalyse');

    /* save to file */
    XLSX.writeFile(wb, `Standortanalyse_${new Date().toISOString()}.xlsx`);
  }

  /**
   * Handles the context menu event on a row, preventing the default browser context menu,
   * and copying the cell values of the row to the clipboard.
   *
   * @param {MouseEvent} event - The mouse event triggered by the context menu.
   * @param {T} row - The data object representing the clicked row.
   * @return {void}
   */
  onRowContextMenu(event: MouseEvent, row: T): void {
    event.preventDefault(); // Prevent the browser's context menu

    // Extract cell values from the row
    const cellValues = this.columns
      .map((column) => (column.callback ? column.callback(row) : row[column.key as keyof T]))
      .join('\t');

    this.copyTextToClipboard(cellValues);
  }

  /**
   * Copies the provided text to the clipboard.
   *
   * @param {string} text - The text to be copied to the clipboard.
   * @return {void}
   */
  copyTextToClipboard(text: string): void {
    const textarea = document.createElement('textarea');
    textarea.value = text;

    // Move the textarea off-screen
    textarea.style.position = 'fixed';
    textarea.style.top = '-99999px';
    textarea.style.left = '-99999px';

    document.body.append(textarea);
    textarea.focus();
    textarea.select();

    try {
      // eslint-disable-next-line deprecation/deprecation
      const successful = document.execCommand('copy');
      if (successful) {
        console.log('Row values copied to clipboard');
        // Optionally, show a success message to the user
      } else {
        console.error('Failed to copy text');
      }
    } catch (error) {
      console.error('Failed to copy text', error);
    }

    textarea.remove();
  }
}
