import { Injectable, inject, provideAppInitializer } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

@Injectable({ providedIn: 'root' })
export class IconRegistryService {
  readonly icons = [
    'academy',
    'add-circle',
    'aml-registration',
    'arrow-right',
    'arrow-right-short',
    'arrow-left-short',
    'arrows-right',
    'arrows-left',
    'arrow-left-circle',
    'building',
    'check',
    'check-list',
    'checked-rounded',
    'chevron-down',
    'close-x',
    'contact-book',
    'crosshair',
    'documents',
    'download',
    'edit',
    'envelope',
    'error',
    'eye-open',
    'eye-closed',
    'faq',
    'file',
    'file-upload',
    'flag',
    'graph',
    'hat',
    'home',
    'info',
    'kyc-check',
    'mail',
    'map-marked',
    'point-of-sale-marker',
    'minimize-down',
    'navigation-menu',
    'navigation-close',
    'paper-clip',
    'person',
    'phone',
    'question',
    'single-arrow-right',
    'sign-out',
    'sort-up',
    'sort-down',
    'sort',
    'success-check',
    'support-fab',
    'trashbin',
    'upload',
    'in-process',
    'warning',
    'wrench-screwdriver',
    'kyc-action-required',
    'kyc-cancelled',
    'kyc-done',
    'kyc-file-search',
    'kyc-in-process',
    'kyc-pause',
    'kyc-tools',
    'kyc-risk-eval-low',
    'kyc-risk-eval-medium',
    'kyc-risk-eval-high',
    'kyc-risk-eval-critical',
  ];

  constructor(
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
  ) {
    this.matIconRegistry.setDefaultFontSetClass('material-symbols-outlined');
  }

  /**
   * it is responsible for registering custom SVG icons
   * using Angular Material's MatIconRegistry. Icons are loaded from the 'assets/icons' directory.
   */
  init(): void {
    for (const icon of this.icons) {
      this.matIconRegistry.addSvgIcon(
        `cop-${icon}`,
        this.domSanitizer.bypassSecurityTrustResourceUrl(`../assets/icons/${icon}.svg`),
      );
    }
  }
}

export const IconRegistryInitializer =
  (IconRegistry: IconRegistryService): (() => void) =>
  () =>
    IconRegistry.init();

export const IconRegistryProvider = provideAppInitializer(() => {
  const initializerFn = IconRegistryInitializer(inject(IconRegistryService));
  return initializerFn();
});
