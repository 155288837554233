<div
  class="dropdown dropdown-end"
  [ngClass]="
    {
      'bg-base-content text-accent': disabled
    } +
    ' ' +
    additionalClassContent
  "
>
  <button
    appRipple
    (click)="toggle()"
    (blur)="toggleDebounced(false)"
    tabindex="0"
    [ngClass]="{
      'bg-base-content text-accent': disabled,
      'bg-base-100 hover:bg-accent': !disabled,
      'border border-brand text-brand outline-blue': _activeKeys.size > 0
    }"
    [disabled]="disabled"
    class="flex w-full flex-row items-center justify-between rounded-md border border-brand px-4 py-2 text-left text-brand shadow focus:border focus:border-brand focus:text-brand focus:outline-blue"
  >
    @if (selectionAsName && this._activeKeys.size > 0) {
      {{ this.selectedOptions }}
    } @else {
      <ng-content></ng-content>
    }
    @if (icon) {
      <mat-icon [class]="iconClasses" class="focus-visible::text-brand" svgIcon="{{ icon }}"></mat-icon>
    }
  </button>
  <ul tabindex="0" class="menu dropdown-content z-50 w-full rounded-md bg-base-100 py-2 shadow">
    @for (option of options; track $index) {
      <li appRipple>
        <a
          [ngClass]="{ 'text-brand bg-base-100': _activeKeys.has(option.value) }"
          (click)="onClick(option)"
          class="flex flex-row justify-between"
        >
          {{ option.title }}
          <mat-icon *ngIf="_activeKeys.has(option.value)" svgIcon="cop-trashbin"></mat-icon>
        </a>
      </li>
    }
  </ul>
</div>
