import { Component, EventEmitter, Input, Output } from '@angular/core';
import { SortOrder } from '../../../pipes/sort-data.pipe';

@Component({
  selector: 'app-sort-button',
  templateUrl: './sort-button.component.html',
})
export class SortButtonComponent {
  @Input() active = false;

  @Output()
  sort = new EventEmitter<SortOrder>();

  sortDirection: SortOrder = '';

  sortDirections: SortOrder[] = ['asc', 'desc', ''];

  toggleSortDirection(event: { preventDefault: () => void; stopPropagation: () => void }): void {
    event.preventDefault();
    event.stopPropagation();

    // toggle through the options
    const currentIndex = this.sortDirections.indexOf(this.sortDirection);
    const nextIndex = (currentIndex + 1) % this.sortDirections.length;
    this.sortDirection = this.sortDirections[nextIndex];
    this.sort.emit(this.sortDirection);
  }
}
