/**
 * Generates an array of numbers in a specified range.
 *
 * @param {number} start - The starting value of the range.
 * @param {number} [end] - The ending value of the range. If not provided, the range will start from 0 and end at the value of `start`.
 * @param {number} [step=1] - The step/increment value. Defaults to 1 if not specified.
 * @returns {number[]} An array of numbers from the start value to the end value, incremented by the step value.
 */
export const range = (start: number, end?: number, step: number = 1): number[] => {
  const result: number[] = [];

  // If only one argument is provided, treat it as the end value and start from 0
  if (end === undefined) {
    end = start;
    start = 0;
  }

  if (step > 0) {
    for (let index = start; index < end; index += step) {
      result.push(index);
    }
  } else if (step < 0) {
    for (let index = start; index > end; index += step) {
      result.push(index);
    }
  }

  return result;
};

/**
 * Creates a debounced function that delays invoking the provided function until
 * after the specified wait time has elapsed since the last time the debounced
 * function was invoked. This is useful for limiting the rate at which a function
 * can fire, such as in cases of handling user input events.
 *
 * @param function_ The function to debounce.
 * @param wait The number of milliseconds to delay.
 * @returns A new debounced function.
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const debounce = <T extends (...arguments_: any[]) => any>(
  function_: T,
  wait: number,
): ((...arguments_: Parameters<T>) => void) => {
  let timeoutId: ReturnType<typeof setTimeout> | undefined;

  return (...arguments_: Parameters<T>) => {
    if (timeoutId !== undefined) {
      clearTimeout(timeoutId);
    }

    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    timeoutId = setTimeout(() => function_(...arguments_), wait);
  };
};
