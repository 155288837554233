import { ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { debounce } from '@core/utils/lodash-equivalent.utils';

const debounceTime = 300;

export type Option = {
  title: string;
  value: string;
  meta?: string;
  showDatePicker?: boolean;
};

/**
 * TODO: there is a bug inside daisyUI which prevents this component from working with default classes.
 * The bug is that the parent div element never loses focus and focus-within, therefore the classes for showing the ul list stay active (visibility and opacity)
 * I tried removing focus and focus-within using renderer2 + ViewChild() without success
 * Therefore there seems to be only one good solution left: removing daisyUI classes and using ngIf instead
 *
 * */
@Component({
  selector: 'app-dropdown',
  templateUrl: './dropdown.component.html',
})
export class DropdownComponent {
  open = false;

  @Input() isMulti: boolean = false;

  @Input() selectionAsName: boolean = false;

  @Input() disabled: boolean | null = false;

  @Input() additionalClassContent: string = '';

  @Input()
  public set activeKeys(values: string | string[] | undefined) {
    if (values) {
      this._activeKeys = Array.isArray(values) ? new Set(values) : new Set([values]);
    }
  }

  _activeKeys: Set<string> = new Set();

  get selectedOptions(): string {
    const optionNames = [...this._activeKeys].map((key) => this.options.find((option) => option.value === key)?.title);
    return optionNames.join(',');
  }

  @Input() options!: Option[];

  @Input() icon?: string;

  @Input() iconClasses?: string;

  @Output()
  // eslint-disable-next-line unicorn/prefer-event-target
  selected = new EventEmitter<Option>();

  constructor(private readonly cdr: ChangeDetectorRef) {}

  onClick(option: Option): void {
    if (this.isMulti) {
      this.handleMultiSelect(option);
    } else {
      this.handleSingleSelect(option);
      this.toggleDebounced();
    }

    this.selected.emit(option);
  }

  /**
   * If explicitly set to a value, take it, otherwise toggle
   * @param setToValue
   */
  toggle(setToValue?: boolean): void {
    // eslint-disable-next-line unicorn/prefer-ternary, unicorn/no-negated-condition
    if (setToValue !== undefined) {
      this.open = setToValue;
    } else {
      this.open = !this.open;
    }
    this.cdr.detectChanges();
  }

  toggleDebounced = debounce(this.toggle.bind(this), debounceTime);

  private handleMultiSelect(option: Option): void {
    if (this._activeKeys.has(option.value)) {
      this._activeKeys.delete(option.value);
    } else {
      this._activeKeys.add(option.value);
    }
  }

  private handleSingleSelect(option: Option): void {
    this._activeKeys.clear();
    this._activeKeys.add(option.value);
  }
}
