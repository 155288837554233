import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { inject, provideAppInitializer } from '@angular/core';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import {
  MSAL_GUARD_CONFIG,
  MSAL_INSTANCE,
  MSAL_INTERCEPTOR_CONFIG,
  MsalBroadcastService,
  MsalGuard,
  MsalInterceptor,
  MsalService,
} from '@azure/msal-angular';
import { IconRegistryProvider } from '@core/services/icon-registry.service';
import { bootUpFactory } from '@core/utils/app-initializer';
import { provideErrorTrackerServices } from '@core/utils/error-tracker-initializer';
import { environment } from '@env/environment';
import { MSALGuardConfigFactory, MSALInstanceFactory, MSALInterceptorConfigFactory } from '@env/msal.config';
import { TranslocoService } from '@jsverse/transloco';
import { DefaultDataServiceConfig } from '@ngrx/data';
import { Store } from '@ngrx/store';
// eslint-disable-next-line import/namespace
import { KerberosTokenInterceptor } from '@/auth/interceptor/kerberos.interceptor';
import { KerberosAuthService } from '@/auth/services/kerberos-auth.service';

export const MinimalProviders = [
  provideAppInitializer(() => {
    const initializerFn = bootUpFactory(
      inject(MsalService),
      inject(Store),
      inject(TranslocoService),
      inject(MsalBroadcastService),
      inject(Router),
      inject(KerberosAuthService),
    );
    return initializerFn();
  }),
  {
    provide: DefaultDataServiceConfig,
    useValue: {
      root: environment.config.dms.url,
      timeout: environment.config.dms.timeout,
    },
  },
  {
    provide: 'ENVIRONMENT_CONFIG',
    useValue: environment.config,
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: MsalInterceptor,
    multi: true,
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: KerberosTokenInterceptor,
    multi: true,
  },
  {
    provide: MSAL_INSTANCE,
    useFactory: MSALInstanceFactory,
    deps: ['ENVIRONMENT_CONFIG'],
  },
  {
    provide: MSAL_GUARD_CONFIG,
    useFactory: MSALGuardConfigFactory,
  },
  {
    provide: MSAL_INTERCEPTOR_CONFIG,
    useFactory: MSALInterceptorConfigFactory,
    deps: ['ENVIRONMENT_CONFIG'],
  },
  ...provideErrorTrackerServices(),
  MsalGuard,
  MsalService,
  MatSnackBarModule,
  IconRegistryProvider,
];
