import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { amlDeskRoutes } from '@core/route-map';
import { EnvironmentService } from '@core/services/environment.service';
import { environment } from '@env/environment';
import { ResponseUserGrantDto } from '@kerberos-compliance/lib-adp-shared/core/iam/authorization/dtos/response.user-grant.dto';
import { Store } from '@ngrx/store';
import { Observable, throwError as observableThrowError } from 'rxjs';
import { catchError, switchMap, take } from 'rxjs/operators';
import { getFeatureForUrl, kerberosConfiguration } from '@/auth/interceptor/kerberos.configuration.interceptor';
import { KerberosAuthService } from '@/auth/services/kerberos-auth.service';
import { AuthActions, LogoutReason } from '@/auth/store/actions/auth.actions';
import { selectGrantByOrganizationId, selectOrganizationId } from '@/auth/store/selectors/auth.selectors';
import { selectAuthTokensAndOwnGrant } from '@/auth/store/selectors/combined-auth.selectors';
import { AuthStateTokens } from '../store/reducers/auth-kerberos.reducers';
import { AuthStateTokenAndOwnGrant } from '../types/auth.types';

@Injectable()
export class KerberosTokenInterceptor implements HttpInterceptor {
  public constructor(
    private store: Store,
    private kerberosAuthService: KerberosAuthService,
    private router: Router,
    private readonly environmentService: EnvironmentService,
  ) {}

  /**
   * Intercepts HTTP requests and adds an authorization token if it is a risk analysis service request.
   *
   * @param {HttpRequest<Request>} request - The HTTP request to intercept.
   * @param {HttpHandler} next - The next HTTP handler in the chain.
   * @returns {Observable<HttpEvent<Event>>} An observable that emits the HTTP event after interception.
   */
  public intercept(request: HttpRequest<Request>, next: HttpHandler): Observable<HttpEvent<Event>> {
    if (!this.isAmlDeskPlatformDomainRequest(request)) {
      return next.handle(request);
    }

    const feature = getFeatureForUrl(request.url); // E.g., 'KYC' or 'RiskAnalysis'

    return this.store.select(selectOrganizationId).pipe(
      take(1),
      switchMap((organizationId) => {
        return this.store.select(selectAuthTokensAndOwnGrant(feature)).pipe(
          take(1),
          switchMap((authTokensAndOwnGrant: AuthStateTokenAndOwnGrant) => {
            const sessionOfGrant = selectGrantByOrganizationId(authTokensAndOwnGrant);
            const kerberosToken = this.getBearerToken(request.url, authTokensAndOwnGrant.authStateTokens);
            const authRequest = this.addAuthHeaders(request, kerberosToken, sessionOfGrant, organizationId);

            return next
              .handle(authRequest)
              .pipe(catchError((error) => this.handleAuthError(error, authRequest, next, authTokensAndOwnGrant)));
          }),
        );
      }),
    );
  }

  /**
   * Checks if the given HttpRequest is targeting the AML Desk platform domain.
   *
   * @param request The HttpRequest object to be evaluated.
   * @return A boolean indicating whether the request URL includes the AML Desk platform domain URL.
   */
  private isAmlDeskPlatformDomainRequest(request: HttpRequest<Request>): boolean {
    return request.url.includes(this.environmentService.baseAdpHost);
  }

  /**
   * Retrieves the Bearer token based on the URL, authStateTokens and debugFlags.
   * Normally returns Azure Bearer token on initial signIn request and Kerberos Bearer token in subsequent requests.
   * In debug mode will always return Kerberos Bearer tokens (skips azure)
   * @param {string} url - The URL to check for the presence of 'sign-in'.
   * @param {AuthStateTokens} authStateTokens - The authentication state tokens object.
   * @returns {string} - The Bearer token.
   * @throws {Error} - If the Bearer token is null or undefined.
   */
  private getBearerToken(url: string, authStateTokens: AuthStateTokens): string {
    const token =
      url.includes(environment.config.adpHost.signIn) && !environment.debugFlags.includes('noExternalAuth')
        ? authStateTokens.accessTokenAzure
        : authStateTokens.accessTokenKerberos;

    if (!token) {
      throw new Error('Bearer token is null or undefined');
    }

    return token;
  }

  /**
   * Retrieves the Kerberos refresh token from the provided authentication state tokens.
   *
   * @param {AuthStateTokens} authStateTokens - The authentication state tokens object.
   * @throws {Error} - Thrown if the Kerberos token is null or undefined.
   * @return {string} - The Kerberos refresh token.
   */
  private getKerberosRefreshToken(authStateTokens: AuthStateTokens): string {
    const refreshTokenKerberos = authStateTokens.refreshTokenKerberos;

    if (!refreshTokenKerberos) {
      void this.router.navigate([amlDeskRoutes.contact]);
      // IMPORTANT!: this should show an error, on localhost we sometimes have issues with 401, check README file for more information
      throw new Error('Kerberos token is null or undefined');
    }

    return refreshTokenKerberos;
  }

  /**
   * Adds authentication headers to an HTTP request.
   *
   * @param {HttpRequest<Request>} request - The HTTP request to which headers will be added.
   * @param {string} kerberosToken - The Kerberos token used for authorization.
   * @param {ResponseUserGrantDto} [grant] - Optional grant information containing tenant and organization IDs.
   * @param {string} [organizationId] - Optional organization ID to be included in the headers.
   * @return {HttpRequest<Request>} The HTTP request with added authentication headers.
   */
  private addAuthHeaders(
    request: HttpRequest<Request>,
    kerberosToken: string,
    grant?: ResponseUserGrantDto,
    organizationId?: string | null,
  ): HttpRequest<Request> {
    return request.clone({
      setHeaders: {
        Authorization: `Bearer ${kerberosToken}`,
        // TypeError: value is undefined RxJS 98 invoke Angular onInvoke RxJS Angular 4 onInvokeTask RxJS invokeTask Angular onInvokeTask RxJS Angular 6 onScheduleTask RxJS Angular 14 sentry-angular-ivy.js:135:20, white screen
        'tenant-id': grant?.tenantId || environment.config.tenant,
        'organization-id': organizationId || grant?.organizationId || '',
      },
    });
  }

  /**
   * Handles authentication errors during HTTP requests.
   *
   * @param {unknown} error The error that occurred during the HTTP request.
   * @param {HttpRequest<Request>} authRequest The original HTTP request that caused the error.
   * @param {HttpHandler} next The next handler in the HTTP request chain.
   * @param {AuthStateTokenAndOwnGrant} authStateTokensAndOwnGrants The current authentication state tokens and grants.
   * @return {Observable<HttpEvent<Event>>} An observable that emits the HTTP event or an error.
   */
  private handleAuthError(
    error: unknown,
    authRequest: HttpRequest<Request>,
    next: HttpHandler,
    authStateTokensAndOwnGrants: AuthStateTokenAndOwnGrant,
  ): Observable<HttpEvent<Event>> {
    if (error instanceof HttpErrorResponse && error.status === kerberosConfiguration.UNAUTHORIZED_STATUS) {
      const retryAttempt = this.getRetryAttempt(authRequest);

      if (retryAttempt < kerberosConfiguration.MAX_RETRY_ATTEMPTS && !authRequest.url.includes('token')) {
        return this.retryRequest(authRequest, retryAttempt, next, authStateTokensAndOwnGrants);
      }

      return observableThrowError(() => error as Error);
    }
    return observableThrowError(() => error as Error);
  }

  /**
   * Returns the retry attempt number from the provided HttpRequest.
   *
   * @param {HttpRequest<Request>} request - The HTTP request object.
   * @return {number} - The retry attempt number.
   */
  private getRetryAttempt(request: HttpRequest<Request>): number {
    const retryAttempt = request.headers.get(kerberosConfiguration.RETRY_ATTEMPT_HEADER);
    return retryAttempt ? Number.parseInt(retryAttempt, 10) : 0;
  }

  /**
   * Retries the given request with a refreshed token.
   *
   * @param {HttpRequest<Request>} authRequest - The original request to be retried.
   * @param {number} retryAttempt - The number of times the request has been retried.
   * @param {HttpHandler} next - The next handler in the request chain.
   * @param {AuthStateTokens} authStateTokensAndOwnGrants - The authentication state tokens.
   * @returns {Observable<HttpEvent<Event>>} - The observable that emits the events of the retried request.
   */
  private retryRequest(
    authRequest: HttpRequest<Request>,
    retryAttempt: number,
    next: HttpHandler,
    authStateTokensAndOwnGrants: AuthStateTokenAndOwnGrant,
  ): Observable<HttpEvent<Event>> {
    return this.kerberosAuthService
      .refreshToken(this.getKerberosRefreshToken(authStateTokensAndOwnGrants.authStateTokens))
      .pipe(
        switchMap((response) => {
          if (!response.access_token) {
            this.store.dispatch(AuthActions.logout({ logoutReason: LogoutReason.SessionExpired }));
            return observableThrowError(() => new Error('Failed to refresh token'));
          }
          const retryRequest = this.addAuthHeaders(
            authRequest.clone({
              setHeaders: {
                [kerberosConfiguration.RETRY_ATTEMPT_HEADER]: `${retryAttempt + 1}`,
              },
            }),
            response.access_token,
            authStateTokensAndOwnGrants.ownGrant,
          );
          return next.handle(retryRequest);
        }),
        catchError((refreshError) => {
          this.store.dispatch(AuthActions.logout({ logoutReason: LogoutReason.SessionExpired }));
          return observableThrowError(() => refreshError as unknown as Error);
        }),
      );
  }
}
