// eslint-disable-next-line import/namespace
import { ErrorHandler, Provider } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '@env/environment';
/* eslint-disable import/no-unresolved,import/namespace */
import * as Sentry from '@sentry/angular';
import packageJSON from '../../../../package.json';

const SENTRY_CONFIG = {
  TRACE_SAMPLE_RATE: 0.2, // Capture 30% of the transactions
  REPLAY_SESSION_SAMPLE_RATE: 0.2,
  REPLAYS_ON_ERROR_SAMPLE_RATE: 1, // Capture 100% of sessions where errors occur
};

const sentryInitConfig = {
  environment: environment.target,
  dsn: environment.config.sentry_dsn,
  release: `aml-desk-frontend@${packageJSON.version}`,
  integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
  tracesSampleRate: SENTRY_CONFIG.TRACE_SAMPLE_RATE,
  replaysSessionSampleRate: SENTRY_CONFIG.REPLAY_SESSION_SAMPLE_RATE,
  replaysOnErrorSampleRate: SENTRY_CONFIG.REPLAYS_ON_ERROR_SAMPLE_RATE,
};

export const initErrorTracker = (): void => {
  Sentry.init(sentryInitConfig);
};

export const provideErrorTrackerServices = (): Provider[] => [
  {
    provide: ErrorHandler,
    useValue: Sentry.createErrorHandler({
      showDialog: false,
    }),
  },
  {
    provide: Sentry.TraceService,
    deps: [Router],
  },
];
