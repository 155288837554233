import { range } from '@core/utils/lodash-equivalent.utils';
import { PaginatorWindow } from './paginator.models';

export const computePaginatorWindow = (
  currentPage: number,
  pageCount: number,
  currentPaginatorWindow: PaginatorWindow,
  windowSize = 3,
): PaginatorWindow => {
  const lastPage = pageCount - 1;

  // only if the currentPage goes out of the window, or the currentPaginatorWindow has no values
  if (
    currentPage < currentPaginatorWindow.start ||
    currentPage > currentPaginatorWindow.end ||
    currentPaginatorWindow.values.length === 0
  ) {
    let windowStart: number = currentPaginatorWindow.start;
    let windowEnd: number = currentPaginatorWindow.end;

    // window starts from 0 and renders next 2 pages if current page is 0 or below
    if (currentPage <= 0) {
      windowStart = 0;
      windowEnd = windowStart + windowSize - 1;
    } else if (currentPage >= lastPage) {
      // if we are on or beyond the last page, window ends at the last page, renders previous 2 pages
      windowEnd = lastPage;
      windowStart = windowEnd - windowSize + 1;
    } else if (currentPage < windowStart) {
      // if the current page goes outside before the window we push the window back, the end of the window is the current page
      windowEnd = currentPage;
      windowStart = currentPage - windowSize + 1;
    } else if (currentPage > windowEnd) {
      // if the currentPage goes outside after the window we push the window forward, the start of the window is the current page
      windowStart = currentPage;
      windowEnd = windowStart + windowSize - 1;
    }

    // again we need to check if the window is not out of the bounds, reset back
    if (windowStart < 0) {
      windowStart = 0;
    }

    if (windowEnd > lastPage) {
      windowEnd = lastPage;
    }

    return {
      values: range(windowStart, windowEnd + 1),
      start: windowStart,
      end: windowEnd,
    };
  }

  return currentPaginatorWindow;
};
