import { MsalGuardConfiguration, MsalInterceptorConfiguration, ProtectedResourceScopes } from '@azure/msal-angular';
import {
  BrowserCacheLocation,
  InteractionType,
  IPublicClientApplication,
  LogLevel,
  PublicClientApplication,
} from '@azure/msal-browser';
import { environment } from '@env/environment';
import { ConfigEnvironment, Environment, EnvironmentsAmlDesk } from '@env/types.environment';

// Example implementation: https://learn.microsoft.com/en-us/samples/azure-samples/ms-identity-ciam-javascript-tutorial/ms-identity-ciam-javascript-tutorial-2-sign-in-angular/

// eslint-disable-next-line n/no-unsupported-features/node-builtins
const isIE = globalThis.navigator.userAgent.includes('MSIE ') || globalThis.navigator.userAgent.includes('Trident/');

const loggerCallback = function (logLevel: LogLevel, message: string): void {
  console.log(message);
};

export const subdomainConfigurationProductionOtherEnvironments = (environmentConfiguration: Environment): string =>
  environmentConfiguration.target === EnvironmentsAmlDesk.Production && environmentConfiguration.productionBuild
    ? 'kerberosb2c'
    : 'kerberoscmstest';

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return { interactionType: InteractionType.Redirect };
}

export function MSALInterceptorConfigFactory(config: ConfigEnvironment): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, (string | ProtectedResourceScopes)[]>();
  const subdomain = subdomainConfigurationProductionOtherEnvironments(environment);

  // Feature Flags Scopes
  protectedResourceMap.set(config.featureFlags.url, [
    `https://${subdomain}.onmicrosoft.com/feature-flags-api/feature.read`,
  ]);

  // DMS Scopes
  protectedResourceMap.set(`${config.dms.url}/documents`, [
    { httpMethod: 'get', scopes: [`https://${subdomain}.onmicrosoft.com/dms-api/documents.read`] },
    { httpMethod: 'post', scopes: [`https://${subdomain}.onmicrosoft.com/dms-api/documents.create`] },
    { httpMethod: 'delete', scopes: [`https://${subdomain}.onmicrosoft.com/dms-api/documents.delete`] },
  ]);
  // RA Scopes
  protectedResourceMap.set(config.adpHost.url, [
    { httpMethod: 'get', scopes: [`https://${subdomain}.onmicrosoft.com/ra-customer-service/ra.read`] },
    { httpMethod: 'patch', scopes: [`https://${subdomain}.onmicrosoft.com/ra-customer-service/ra.update`] },
    { httpMethod: 'post', scopes: [`https://${subdomain}.onmicrosoft.com/ra-customer-service/ra.update`] },
  ]);
  // MS Graph Scopes
  protectedResourceMap.set('https://graph.microsoft.com/v1.0/me', ['User.Read']);

  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap,
  };
}

/**
 * MSAL Configurations
 * https://www.npmjs.com/package/@azure/msal-angular
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-angular/docs/redirects.md
 */
export function MSALInstanceFactory(config: ConfigEnvironment): IPublicClientApplication {
  return new PublicClientApplication({
    auth: {
      clientId: config.msal.clientId,
      authority: `https://${config.sso_url}/${config.msal.tenantId}/${config.msal.policy}`,
      knownAuthorities: [config.sso_url],
    },
    cache: {
      cacheLocation: BrowserCacheLocation.LocalStorage,
      storeAuthStateInCookie: isIE, // set to true for IE 11
    },
    system: {
      allowPlatformBroker: true,
      loggerOptions: {
        loggerCallback,
        logLevel: environment.target === EnvironmentsAmlDesk.Production ? LogLevel.Error : LogLevel.Info,
        piiLoggingEnabled: environment.productionBuild,
      },
    },
  });
}
