export enum ProgressStatus {
  Closed = 'Closed',
  Waiting = 'Waiting',
  InProgress = 'InProgress',
  Draft = 'Draft',
}

export enum FlagEnum {
  NewComment = 'NewComment',
  // NewDocument = 'NewDocument',
  // InformationMissing = 'InformationMissing',
}

export enum Risks {
  Low = 'Low',
  Medium = 'Medium',
  High = 'High',
  Critical = 'Critical',
}

export enum WorkItemTypes {
  DdCheckPoS = 1,
  DdCheckIndividual = 2,
  DdCheckOrganization = 3,
  KycCheckIndividual = 4,
  KycCheckOrganization = 5,
}

export enum WorkItemFlags {
  NewComment = 'NewComment',
  NoComments = 'NoComments',
}

export enum WorkItemPriority {
  Lowest = 0,
  Low = 1,
  Medium = 2,
  High = 3,
  Highest = 4,
  Critical = 5,
}

export enum TranslationKeys {
  Draft = 'draft',
  InProcess = 'inProcess',
  ActionRequired = 'actionRequired',
  Done = 'done',
  OnHold = 'onHold',
  Cancelled = 'cancelled',
}
