<ng-container *transloco="let t;">
  <div class="flex flex-col-reverse items-center md:flex-row">
    <div class="mb-4 flex items-center md:mb-0">
      <app-paginator-button
        class="mr-2"
        [icon]="true"
        [disabled]="currentPage === 1"
        (click)="changePage(currentPage - 1)"
      >
        {{ t('pagination.previous') }}
      </app-paginator-button>

      <app-paginator-button
        class="mr-2"
        [icon]="true"
        [disabled]="currentPage === pageCount - 1 || !hasMore"
        (click)="changePage(currentPage + 1)"
      >
        {{ t('pagination.next') }}
      </app-paginator-button>
    </div>
  </div>
</ng-container>
